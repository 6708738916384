export const checkIfHasVariants = (item) =>
  item.sizes && item.sizes.length>0 ||
  item.neutralOptions && item.neutralOptions.length > 0

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (
      (item.sizes &&
        item.sizes.length > 0 &&
        selectedVariants &&
        selectedVariants.sizeSelected.value === "") ||
        (item.neutralOptions && 
          item.neutralOptions.length > 0 &&
          selectedVariants &&
          selectedVariants.neutralOptionSelected.value === "")
        ||
        (item.sizes &&
          item.sizes.length > 0 &&
          selectedVariants &&
          selectedVariants.sizeSelected.value !== "" &&selectedVariants.sizeSelected.colors &&
          selectedVariants.sizeSelected.colors.length > 0 && selectedVariants.colorSelected.value==="")
    );
  }
  return checkIfHasVariants(item);
};
